import Layout from "@/components/Layout/Layout";
import { Landing } from "@/features/misc";
import Head from "next/head";
import type { ReactElement } from "react";

function Careme() {
	return (
		<>
			<Head>
				<title>ケアミー | 生理やPMSの予測・共有アプリ</title>
				<meta
					name="description"
					content="ケアミーは、PMSの予測・共有アプリです。生理日やPMSを予測し、LINEでパートナーに共有できます。"
				/>
			</Head>

			<Landing />
		</>
	);
}

Careme.getLayout = function getLayout(page: ReactElement) {
	return <Layout hideHeader>{page}</Layout>;
};

export default Careme;
